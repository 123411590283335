<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <v-text-field
      v-model="value"
      :disabled="disabled"
      :style="style"
      type="number"
      :min="min"
      :max="max"
      :step="step"
      @change="onChange"
      @focus="onFocus"
    />
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeNumberInteger',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      value: this.getValue()
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = false
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style = `outline: 1px solid ${outlineColor};`
      }
      return style
    },
    disabled() {
      return this.state.disabled
    },
    min() {
      const settings = this.cell.settings || {}
      if (!/^-?[0-9]+/.test(settings.min)) {
        return false
      }
      return settings.min
    },
    max() {
      const settings = this.cell.settings || {}
      if (!/^-?[0-9]+/.test(settings.max)) {
        return false
      }
      return settings.max
    },
    step() {
      const settings = this.cell.settings || {}
      if (!/^-?[0-9]+/.test(settings.step)) {
        return false
      }
      return settings.step
    }
  },
  mounted() {
    this.applySQLData()
  },
  methods: {
    applySQLData() {
      const builder = this.data.ChecklistBuilder
      if (typeof builder.applySQLData === 'function') {
        builder.applySQLData(this.checklist.index, this.cell, this.dataViewer).then((result) => {
          if (!result || typeof result !== 'object' || !result.value) {
            return
          }
          this.onChange(result.value)
        })
      }
    },
    getValue() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      return builder.getCellValue(index, this.cell, this.clone)
    },
    validate(value) {
      if (value === '') {
        return true
      }
      // Überprüfung ob Zahl oder Fließkommazahl.
      if (!/^-?[0-9]+$/.test(value)) {
        return false
      }
      const builder = this.data.ChecklistBuilder
      value = parseFloat(value || 0)
      // Überprüfung ob Intervall eingehalten wird.
      if (
        this.step !== false &&
        builder.moduloFloatSafe(value, parseFloat(this.step || '1')) !== 0
      ) {
        return false
      }
      // Überprüfung ob Minimum unterschritten wurde.
      if (
        this.min !== false && this.min !== '' &&
        value < parseFloat(this.min || 0)
      ) {
        return false
      }
      // Überprüfung ob maximum überschritten wurde.
      if (
        this.max !== false && this.max !== '' &&
        value > parseFloat(this.max || 0)
      ) {
        return false
      }
      return true
    },
    onChange(value) {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const previousValue = builder.getCellValue(index, this.cell, this.clone, false)
      if (!this.validate(value)) {
        this.value = previousValue
        return
      }
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
    },
    onFocus(event) {
      // Fix für den Firefox (ja wirklich!), da CHANGE-Event bei einer Änderung
      // von 1 über die Spinner (Buttons) nicht beachtet wird
      const cellvalue = this.getValue()

      if (cellvalue == null || cellvalue.toString() !== this.value.toString()) {
        this.onChange(event.target.value)
      }
    }
  }
}
</script>
